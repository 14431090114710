console.log('%cMade with ❤️ by Pyxys%c', 'background: #63c3a4; color:#fff;padding:1px 10px;border-radius: 0px;line-height: 26px;', '');

// ADICIONA A SOMBRA SERRILHADA ÁS DIVS COM A CLASSE .card-shadow-serrilhado
$('<div>', {
  class: 'shadow-serrilhada'
}).appendTo('.card-shadow-serrilhado');

// HEADER
$(document).ready(function () {
  $('.navbar-collapse').removeClass('d-none');
  $('.navbar-collapse').hide();

  // Animação de rolagem de palavras da página 404
  var duration_animation404 = 50000;
  $('.animation-404 .div-passador').addClass('animate').css({
    'animation-duration': `${duration_animation404}ms`
  });
  setInterval(() => { 
    $('.animation-404 .div-passador').eq('0').toggleClass('order-2');
  }, duration_animation404)
  
  // Ouvindo evento de collapse da barra de navegação
  $('button#toggle-nav').click(function (e) { 
    e.preventDefault();
    $(this).addClass('click-effect').attr('disabled', 'disabled');
    setTimeout(() => {
      $(this).removeClass('click-effect').removeAttr('disabled');
    }, 200);
    $('.div-bar, .traco-1, .traco-2, .traco-3').toggleClass('close-icon');
    $('.logo img').toggleClass('zoom-logo');
    $('.navbar-collapse').fadeToggle(200);
  });

  // botão Conteúdo Rico header menu
  $('#target-toggle-nav .row a').click(function (e) { 
    var nameTitle = $(this).attr('title');
    var wDevice = $( window ).width();  
    if((nameTitle === 'Conteúdo Rico')&&(wDevice <= 1199)){
      $('button#toggle-nav').trigger("click");
    }
  });
  
  // webstories
  $('.lk-iframe').on('click', function(e){
    var lkWebstore = $(this).attr("data-link");
    e.preventDefault();
    $('#modal-webstories iframe').attr('src', lkWebstore);
    $('#modal-webstories .modal-content .load-webstories').css('display', 'block');
  });
  
  $('.btn-close-modal').on('click', function(){
    $('#modal-webstories iframe').removeAttr('src');
    $('#modal-webstories .modal-content .load-webstories').css('display', 'none');
  });

  $('.fullscreen-GPBB').removeClass('d-none').hide();
  $('.btn-search-fixed').on('click', function(){
    $('.fullscreen-GPBB').fadeIn(300);
  });
  $('.btn-close-modal-GPBB').on('click', function(){
    $('.fullscreen-GPBB').fadeOut(300);
  });

  //Cookies configuration
  $('.cookies-msg').removeClass('d-none');
  $('.cookies-msg').hide();
  if (!localStorage.getItem('GDPR:accepted', true) === true) {
    $('.cookies-msg').show();
  }

  $('.cookies-msg button').click(function (e) { 
    e.preventDefault();
    $('.cookies-msg').fadeOut();
    localStorage.setItem('GDPR:accepted', true)
  });
});

//form mask
function mask(o,f){
  v_obj=o
  v_fun=f
  v_obj.value=v_fun(v_obj.value)
}
function replaceTel(v){
  v=v.replace(/\D/g,"");
  v=v.replace(/^(\d{2})(\d)/g,"($1) $2");
  v=v.replace(/(\d)(\d{4})$/,"$1-$2");
  return v;
}
function maskFocus(event){
  event.placeholder = "(xx) x xxxx-xxxx";
}
function maskBlur(event){
  event.placeholder = "Telefone (xx) x xxxx-xxxx";
  
  if(event.value.length < event.maxLength){
      event.style.borderBottom  = "2px solid red";
  }else{
      event.style.borderBottom  = "2px dotted #000";
  }
}
